<template>
  <div class="container">
    <div class="header-img">
      <span class="name">{{ leaguesName }}</span>
      <span class="club">{{ squadName }}</span>
      <img class="qrcode" :src="qrcode" alt="" />
    </div>
    <div v-for="member in members" :key="member" class="member">
      <div class="item"></div>
      <div class="item">{{ member }}</div>
      <div class="item"></div>
      <div class="item"></div>
      <div class="item"></div>
    </div>
    <template v-if="members.length < minLength">
      <div v-for="i of minLength - members.length" :key="i" class="member">
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>
      </div>
    </template>
  </div>
</template>

<script>
import $fetch from "@/utils/fetch.js";
export default {
  name: "scoreSheet",
  data() {
    return {
      minLength: 19,
      campaignId: "",
      leaguesName: "",
      squadName: "",
      members: "",
      qrcode: "",
    };
  },
  created() {
    let { campaignId, leaguesName, squadName, members } = this.$route.query;
    this.campaignId = campaignId;
    this.leaguesName = leaguesName;
    this.squadName = squadName;
    this.members = JSON.parse(members);
    this.getWechatCode(campaignId);
  },
  methods: {
    /** 获取活动的小程序码 */
    getWechatCode(campaignId) {
      const _ = this;
      $fetch
        .post("SharePost", {
          campaignId,
        })
        .then((code) => {
          _.qrcode = "data:image/jpeg;base64, " + code.mini_program_code_image;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
$PuHuiH: AlibabaPuHuiTiH Alibaba-PuHuiTi-H PingFangSC-Medium;
$multipleCount: 1.26;

.container {
  position: relative;
  padding-bottom: px2rem(16 * $multipleCount);

  > .header-img {
    position: relative;
    display: block;
    width: 100%;
    height: px2rem(274 * $multipleCount);
    background: url("https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/01899151-acbc-e84c-dbbc-6ff0d1bab19c")
      no-repeat left top / cover;

    > .name {
      @include ellipsis;
      position: absolute;
      left: px2rem(16 * $multipleCount);
      top: px2rem(56 * $multipleCount);
      min-width: px2rem((354 + 30) * $multipleCount);
      max-width: calc(100vw - px2rem(44 * $multipleCount));
      height: px2rem(40 * $multipleCount);
      line-height: px2rem(40 * $multipleCount);
      color: #fff;
      font-size: px2rem(24 * $multipleCount);
      padding-left: px2rem(10 * $multipleCount);
      font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
      background: linear-gradient(to right, #000 0 calc(100% - px2rem(28 * $multipleCount)), transparent calc(100% - px2rem(28* $multipleCount)) 100%);

      &::before {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: px2rem(29 * $multipleCount);
        height: 100%;
        background-color: #77f99b;
        clip-path: polygon(0 0, 0 100%, 28% 100%, 100% 0);
      }

      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: px2rem(29 * $multipleCount);
        height: 100%;
        background-color: #000;
        clip-path: polygon(0 0, 0 100%, 100% 100%, 30% 0);
        z-index: 1;
      }
    }

    > .club {
      @include ellipsis;
      position: absolute;
      left: px2rem(16 * $multipleCount);
      top: px2rem(109 * $multipleCount);
      width: px2rem(200 * $multipleCount);
      color: #222;
      font-family: $PuHuiH;
      font-size: px2rem(16 * $multipleCount);
      font-weight: 900;
      line-height: px2rem(24 * $multipleCount);
    }

    > .qrcode {
      position: absolute;
      right: px2rem(18 * $multipleCount);
      top: px2rem(103 * $multipleCount);
      width: px2rem(92 * $multipleCount);
      height: px2rem(92 * $multipleCount);
      object-fit: cover;
    }
  }

  > .member {
    @include row;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: px2rem(16 * $multipleCount);
    margin-right: px2rem(16 * $multipleCount);
    height: px2rem(30 * $multipleCount);
    line-height: px2rem(30 * $multipleCount);
    color: #000;
    text-align: center;
    font-weight: 500;
    font-size: px2rem(18 * $multipleCount);
    font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
    border-left: px2rem(4 * $multipleCount) solid #333;
    border-right: px2rem(4 * $multipleCount) solid #333;
    border-bottom: px2rem(2 * $multipleCount) solid #333;

    &:last-child {
      border-bottom: px2rem(4 * $multipleCount) solid #333;
    }

    > .item {
      font-family: PingFangSC-Regular;

      &:first-child {
        width: px2rem(82 * $multipleCount);
        height: 100%;
        border-right: px2rem(2 * $multipleCount) solid #333;
      }

      &:nth-child(2) {
        @include ellipsis;
        width: px2rem(140 * $multipleCount);
        height: 100%;
        border-right: px2rem(2 * $multipleCount) solid #333;
      }

      &:nth-child(n + 3) {
        width: px2rem(109 * $multipleCount);
        height: 100%;
        border-right: px2rem(2 * $multipleCount) solid #333;
      }

      &:last-child {
        border-right: 0;
      }

      &:nth-child(2n + 1) {
        background-color: #b1ffd9;
      }
    }
  }
}
</style>
